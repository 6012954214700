import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Dashboard } from './pages/Dashboard';
import { Categories } from './pages/Categories';
import { Products } from './pages/Products';

import { createTheme } from "react-data-table-component";
import { MainProvider } from './hook/main';

createTheme('solarized', {
  text: {
    primary: '#fff',
    secondary: '#fff',
  },
  background: {
    default: 'bg-base-200',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#555',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}, 'dark');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>

    <BrowserRouter>
      <MainProvider>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/categories' element={<Categories />} />
            <Route path='/products' element={<Products />} />
          </Route>
        </Routes>
      </MainProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
