import { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { InputText } from '../components/InputText';
import { InputSelect } from '../components/InputSelect';
import { ICategories } from '../pages/Categories';

interface Props {
  data: Array<ICategories>;
}

export function CategoryTable({ data }: Props) {
  const [filter, setFilter] = useState('name')
  const [search, setSearch] = useState('')

  const tableColumns: TableColumn<ICategories>[] = [{
    name: 'Id',
    width: "60px",
    selector: row => row.id
  },
  {
    name: 'Nome',
    selector: row => row.name
  }, {
    name: '',
    width: "120px",
    cell: (row) => (
      <div className='flex gap-2 justify-start'>
        <button className='btn btn-xs btn-square btn-outline text-yellow-500' onClick={() => { }}><FontAwesomeIcon icon={icon({ name: 'edit' })} /></button>
        <button className='btn btn-xs btn-square btn-outline text-red-500' onClick={() => { }}><FontAwesomeIcon icon={icon({ name: 'trash' })} /></button>
      </div>
    )
  }]

  let filteredItems = data.filter((item: any) => item[filter].toString().toUpperCase().includes(search.toUpperCase()))

  return (
    <div className="flex flex-col">
      <div className="flex gap-2">
        <div className="flex-2">
          <InputSelect
            label="filtro"
            options={[{
              value: 'name',
              text: 'Nome'
            }, {
              value: 'id',
              text: 'Id'
            }]}
            defaultValue={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <div className="flex-1">
          <InputText label="Busca" placeholder="Digite o que procura." value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>
      </div>
      <div className='rounded-md mt-2'>
        <DataTable
          pagination
          paginationPerPage={10}
          columns={tableColumns}
          data={filteredItems}
          theme='solarized'
        />

      </div>
    </div>


  )
}