import { SelectHTMLAttributes } from 'react';

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  errorMsg?: string;
  placeholder?: string;
  options: {
    value: string;
    text: string;
  }[];
}

export function InputSelect({ label = '', errorMsg = '', placeholder = '', options, defaultValue = '', ...rest }: Props) {
  return (
    <div className="form-control w-full">
      <label className="label">
        <span className="label-text uppercase font-bold text-xs">{label}</span>
      </label>
      <select className="select select-bordered" defaultValue={defaultValue} {...rest}>
        <option disabled>Selecione uma opção</option>
        {
          options.map(item => <option key={item.value} value={item.value}>{item.text}</option>)
        }
      </select>
      {
        errorMsg &&
        <label className="label">
          <span className="label-text-alt">{errorMsg}</span>
        </label>
      }

    </div>
  )
}