import { useEffect, useState } from "react";
import { ProductTable } from "../tables/ProductTable";
import { ProductForm } from "../forms/ProductForm";
import { useMain } from "../hook/main";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

export interface IProduct {
  id: number;
  name: string;
  price: number;
}

export function Products() {
  const [products, setProducts] = useState<IProduct[]>([])
  const [activeTab, setActiveTab] = useState(0)
  const [formStatus, setFormStatus] = useState<'idle' | 'inserting' | 'editing'>('idle')
  const ipcRenderer = (window as any).ipcRenderer

  function handleNewRegister() {
    setFormStatus('inserting')
    setActiveTab(1)
  }

  function handleCancel() {
    setFormStatus('idle')
    setActiveTab(0)
  }

  useEffect(() => {
    ipcRenderer.on('products:get', (args: any) => {
      setProducts(args)
    })

    ipcRenderer.send('products:get', [])

  }, [ipcRenderer])

  return (
    <div className="">
      <div className="flex-3 flex gap-2 justify-between mb-2">
        <h1 className="font-bold text-2xl">Produtos</h1>
        <div className="flex gap-2">
          <button className='btn btn-error btn-sm' disabled={formStatus === 'idle'} onClick={handleCancel}>
            <FontAwesomeIcon icon={icon({ name: 'times' })} />
            Cancelar
          </button>
          <button className='btn btn-primary btn-sm' onClick={handleNewRegister}>
            <FontAwesomeIcon icon={icon({ name: 'plus' })} />
            Adicionar
          </button>
        </div>
      </div>
      <div className="tabs tabs-boxed">
        <button className={`tab ${activeTab === 0 && 'tab-active'}`} onClick={() => setActiveTab(0)} disabled={formStatus !== 'idle'}>Consulta</button>
        <button className={`tab ${activeTab === 1 && 'tab-active'}`} onClick={() => setActiveTab(1)} disabled={formStatus === 'idle'}>Cadastro</button>
        <button className={`tab ${activeTab === 2 && 'tab-active'}`} onClick={() => setActiveTab(2)} disabled={formStatus === 'idle'}>Tab 3</button>
      </div>
      <div className={`overflow-y-auto`}>
        {
          activeTab === 0 ?
            <ProductTable data={products} />
            : <ProductForm />
        }
      </div>
    </div>
  )
}