import { Outlet } from 'react-router-dom';
import { Navbar } from './Navbar';

export function Layout() {

  return (
    <div className='oveflow-hidden'>
      <Navbar />
      <div className={`p-4 mt-0 pt-2 oveflow-hidden`}>
        <Outlet />
      </div>
    </div>
  )
}