import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

export function Navbar() {
  return (
    <div className="navbar bg-base-200">
      <div className="navbar-start">
        <div className="dropdown">
          <label tabIndex={0} className="btn btn-ghost btn-circle">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
          </label>
          <ul tabIndex={0} className="menu dropdown-content mt-3 z-[1] p-2 shadow bg-base-200 rounded-box w-52">
            <li>
              <h2 className="menu-title text-xs">MENU</h2>
              <ul>
                <li>
                  <a>
                    <FontAwesomeIcon icon={icon({ name: 'cash-register' })} />
                    PDV
                  </a>
                </li>
                <li>
                  <a>
                    <FontAwesomeIcon icon={icon({ name: 'money-bill-trend-up' })} />
                    Contas à receber
                  </a>
                </li>
                <li>
                  <a>
                    <FontAwesomeIcon icon={icon({ name: 'money-bill-transfer' })} />
                    Contas à receber
                  </a>
                </li>
                <li>
                  <a>
                    <FontAwesomeIcon icon={icon({ name: 'file-lines' })} />
                    Relatórios
                  </a>
                </li>
              </ul>
              <h2 className="menu-title text-xs">CADASTROS</h2>
              <ul>
                <li>
                  <a href='/categories'>
                    <FontAwesomeIcon icon={icon({ name: 'boxes-stacked' })} />
                    Categorias
                  </a>
                </li>
                <li>
                  <a href='/products'>
                    <FontAwesomeIcon icon={icon({ name: 'cart-flatbed' })} />
                    Produtos
                  </a>
                </li>
                <li>
                  <a>
                    <FontAwesomeIcon icon={icon({ name: 'users' })} />
                    Clientes
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div className="navbar-center">
        <a href="/" className="btn btn-ghost normal-case text-xl">STORE</a>
      </div>
      <div className="navbar-end">
        <button className="btn btn-ghost btn-circle">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
        </button>
        <button className="btn btn-ghost btn-circle">
          <div className="indicator">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" /></svg>
            <span className="badge badge-xs badge-primary indicator-item"></span>
          </div>
        </button>
        <div className="dropdown dropdown-end">
          <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
            <div className="w-10 rounded-full">
              <img src="/images/stock/photo-1534528741775-53994a69daeb.jpg" />
            </div>
          </label>
          <ul tabIndex={0} className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-200 rounded-box w-52">
            <li>
              <a className="justify-between">
                Profile
                <span className="badge">New</span>
              </a>
            </li>
            <li><a>Settings</a></li>
            <li><a>Logout</a></li>
          </ul>
        </div>
      </div>
    </div>
  )
}