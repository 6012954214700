import { useEffect, useState } from "react";
import { InputSelect } from "../components/InputSelect";
import { InputText } from "../components/InputText";
import { Table, ITableColumn } from "../components/Table";
import { CategoryTable } from "../tables/CategoryTable";

export interface ICategories {
  id: number;
  name: string;
}

export function Categories() {
  const [categories, setCategories] = useState<ICategories[]>([])
  const [activeTab, setActiveTab] = useState(1)

  const ipcRenderer = (window as any).ipcRenderer

  useEffect(() => {
    ipcRenderer.on('categories:get', (args: any) => {
      setCategories(args)
    })

    ipcRenderer.send('categories:get', [])

  }, [ipcRenderer])

  return (
    <div>
      <div className="tabs tabs-boxed">
        <button className={`tab disabled ${activeTab == 0 && 'tab-active'}`} onClick={() => setActiveTab(0)}>Consulta</button>
        <button className={`tab ${activeTab == 1 && 'tab-active'}`} onClick={() => setActiveTab(1)}>Cadastro</button>
        <button className={`tab ${activeTab == 2 && 'tab-active'}`} onClick={() => setActiveTab(2)}>Tab 3</button>
      </div>
      {
        activeTab == 0 ?
          <CategoryTable data={categories} />
          : ''
      }
    </div>
  )
}