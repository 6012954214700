import { InputHTMLAttributes } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errorMsg?: string;
  placeholder?: string;
}

export function InputText({ label = '', errorMsg = '', placeholder = '', ...rest }: Props) {
  return (
    <div className="form-control w-full">
      <label className="label">
        <span className="label-text uppercase font-bold text-xs">{label}</span>
      </label>
      <input type="text" placeholder={placeholder} className="input input-bordered w-full input-md h-10" {...rest} />
      {
        errorMsg &&
        <label className="label">
          <span className="label-text-alt text-red-500">Bottom Left label</span>
        </label>
      }

    </div>
  )
}