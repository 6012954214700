import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

interface MainProviderProps {
  children: ReactNode;
}

interface IMainContextData {

}


export const MainContext = createContext({} as IMainContextData)

function MainProvider({ children }: MainProviderProps) {

  useEffect(() => {
  }, [])

  return (
    <MainContext.Provider value={{}}>
      {children}
    </MainContext.Provider>
  )
}

function useMain() {
  const context = useContext(MainContext)
  return context
}

export { MainProvider, useMain }