import { InputSelect } from "../components/InputSelect";
import { InputText } from "../components/InputText";
import { InputTextarea } from "../components/InputTextarea";

export function ProductForm() {
  return (
    <form>
      <div className="flex flex-wrap gap-2">
        <div className="sm:flex-2 md:flex-1">
          <InputText label="codigo de barras" placeholder="" />
        </div>
        <div className="sm:flex-2 md:flex-1">
          <InputText label="nome" placeholder="" />
        </div>
        <div className="md:flex-2">
          <InputSelect
            label="categoria"
            options={[{
              value: 'name',
              text: 'Nome'
            }, {
              value: 'id',
              text: 'Id'
            }]}
          />
        </div>
        <div className="md:flex-2">
          <InputSelect
            label="tipo"
            options={[{
              value: 'name',
              text: 'Nome'
            }, {
              value: 'id',
              text: 'Id'
            }]}
          />
        </div>

      </div>
      <div className="flex flex-wrap gap-2">
        <div className="sm:flex-2 md:flex-1">
          <InputTextarea label='descrição' />
        </div>
      </div>
    </form>
  )
}