import { InputHTMLAttributes } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errorMsg?: string;
  placeholder?: string;
}

export function InputTextarea({ label = '', errorMsg = '', placeholder = '', ...rest }: Props) {
  return (
    <div className="form-control w-full">
      <label className="label">
        <span className="label-text uppercase font-bold text-xs">{label}</span>
      </label>
      <textarea className="textarea textarea-bordered h-24" placeholder="Descrição"></textarea>
      {
        errorMsg &&
        <label className="label">
          <span className="label-text-alt text-red-500">Bottom Left label</span>
        </label>
      }

    </div>
  )
}